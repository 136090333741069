import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import Body from '../atoms/typography/Body';
import BodySmall from '../atoms/typography/BodySmall';
import { formatPrice } from '../helpers';
import { useSettlementData } from '../graphql/hooks/useGetSettlementData';
import { WarningIcon } from '../atoms/icons/WarningIcon';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import { useUpdateSettlementAccountPlanToCurrent } from '../graphql/hooks/useUpdateSettlementAccountPlanToCurrent';

const AccountRow = styled(Row)`
    border-bottom: 1px solid ${theme.colors.textSecondary};
    padding: 8px;

    &:hover {
        background-color: ${theme.colors.primary};
    }
`;

const SettlementDataAccounting = ({ settlementId, clientIdentifier }) => {
    const { settlementData, error, loading } = useSettlementData(clientIdentifier, settlementId);
    const [updateSuccessful, setUpdateSuccessful] = React.useState(false);

    const onCompletedUpdateSettlementAccountPlan = () => {
        if (updateSettlementAccountPlanData?.success) {
            setUpdateSuccessful(true);
            setTimeout(() => {
                setUpdateSuccessful(false);
            }, 3000);
        }
    };
    const onErrorUpdateSettlementAccountPlan = () => {
        console.log('Something went wrong');
    };
    const {
        updateSettlementAccountPlanToCurrent,
        data: updateSettlementAccountPlanData,
        loading: updateSettlementAccountPlanLoading,
        error: updateSettlementAccountPlanError,
    } = useUpdateSettlementAccountPlanToCurrent({ onCompletedUpdateSettlementAccountPlan, onErrorUpdateSettlementAccountPlan });

    const onUpdateSettlementAccountPlan = async () => {
        try {
            await updateSettlementAccountPlanToCurrent({
                variables: {
                    input: {
                        settlementId: settlementId,
                        clientIdentifier: clientIdentifier,
                    },
                },
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const data = settlementData?.accounting;

    if (loading) {
        return <Body>Loading...</Body>;
    }

    return (
        <div>
            {settlementData?.settlementAccountPlanIsOutdated && settlementData.exportStatus === 'EXPORT_FAILED' && (
                <div>
                    <HorizontalSpacer spacing={1} />
                    <Row nogutter align="center">
                        <WarningIcon color={theme.colors.warning} />
                        <VerticalSpacer spacing={0.5} />
                        {updateSettlementAccountPlanLoading ? (
                            <BodySmall color={theme.colors.warning}>Uppdaterar...</BodySmall>
                        ) : (
                            <BodySmall color="white" style={{ width: '90%' }}>
                                Du har uppdaterat din kontoplan sedan denna avräkning. Vill du uppdatera avräkningens kontoplan till dina nuvarande
                                inställningar? Den uppdaterade avräkningen kommer gälla när du testar att synka bokföringen igen, samt när du laddar ner
                                filerna.{' '}
                                <b onClick={onUpdateSettlementAccountPlan} style={{ cursor: 'pointer' }}>
                                    <u>Klicka här för att uppdatera avräkningens kontoplan</u>
                                </b>
                            </BodySmall>
                        )}
                    </Row>
                </div>
            )}
            {updateSuccessful && <BodySmall color={theme.colors.success}>Kontoplanen har uppdaterats</BodySmall>}
            <HorizontalSpacer spacing={2} />

            <AccountRow nogutter>
                <Col lg={1} xs={0} sm={0} />
                <Col lg={5} xs={5} sm={4}>
                    <Hidden xs sm>
                        <Body color="white">Benämning</Body>
                    </Hidden>
                    <Visible xs sm>
                        <Body color="white">Konto</Body>
                    </Visible>
                </Col>
                <Col lg={5} xs={5} sm={4}>
                    <Body color="white">Belopp</Body>
                </Col>
                <Col lg={0} xs={4} sm={4}></Col>
                <Col lg={1} xs={0} sm={4} />
            </AccountRow>

            {data && (
                <div>
                    {data?.accountsReceivable && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.accountsReceivable?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Kundfordringar</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Kundfordringar</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.accountsReceivable?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.accountsReceivable?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.accountsReceivable?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.accountsReceivable?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.businessAccount && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.businessAccount?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Bankgiro, Bank</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Bankgiro, Bank</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.businessAccount?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.businessAccount?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.businessAccount?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.businessAccount?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.clearingAccountFactoring && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.clearingAccountFactoring?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Avräkning factoring</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Avräkning factoring</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.clearingAccountFactoring?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.clearingAccountFactoring?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.clearingAccountFactoring?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.clearingAccountFactoring?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.clearingAccountPledgedReceivable && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.clearingAccountPledgedReceivable?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Belånade kundfordringar</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Belånade kundfordringar</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.clearingAccountPledgedReceivable?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.clearingAccountPledgedReceivable?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.clearingAccountPledgedReceivable?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>
                                        {data?.clearingAccountPledgedReceivable?.amount < 0 ? 'Kredit' : 'Debit'}
                                    </BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.factoringCharges && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.factoringCharges?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Factoringavgifter</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Factoringavgifter</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.factoringCharges?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.factoringCharges?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.factoringCharges?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.factoringCharges?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.inputVat && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.inputVat?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Ingående moms SE</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Ingående moms SE</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.inputVat?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.inputVat?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.inputVat?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.inputVat?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.interestExpenseToCreditInstitutions && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.interestExpenseToCreditInstitutions?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Räntekostnader för kortfristiga skulder</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Räntekostnader för kortfristiga skulder</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.interestExpenseToCreditInstitutions?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.interestExpenseToCreditInstitutions?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.interestExpenseToCreditInstitutions?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>
                                        {data?.interestExpenseToCreditInstitutions?.amount < 0 ? 'Kredit' : 'Debit'}
                                    </BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.otherCurrentLiabilities && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.otherCurrentLiabilities?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Övriga kortfristiga skulder</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Övriga kortfristiga skulder</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.otherCurrentLiabilities?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.otherCurrentLiabilities?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.otherCurrentLiabilities?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.otherCurrentLiabilities?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.otherExternalServices && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.otherExternalServices?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Övriga externa tjänster</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Övriga externa tjänster</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.otherExternalServices?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.otherExternalServices?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.otherExternalServices?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.otherExternalServices?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.pledgedReceivable && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.pledgedReceivable?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Factoringgruppen deposition</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Factoringgruppen deposition</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.pledgedReceivable?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.pledgedReceivable?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.pledgedReceivable?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.pledgedReceivable?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.roundingOff && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.roundingOff?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">Öresavrundning</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">Öresavrundning</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.roundingOff?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.roundingOff?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.roundingOff?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.roundingOff?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                    {data?.suspenseAccount && (
                        <AccountRow nogutter align="center">
                            <Col lg={1} xs={5} sm={4}>
                                <BodySmall color={theme.colors.textSecondary}>{data?.suspenseAccount?.accountNumber}</BodySmall>
                                <Visible xs sm>
                                    <BodySmall color="white">OBS-konto (Observationskonto)</BodySmall>
                                </Visible>
                            </Col>
                            <Hidden xs sm>
                                <Col lg={5}>
                                    <Body color="white">OBS-konto (Observationskonto)</Body>
                                </Col>
                            </Hidden>
                            <Col lg={5} xs={5} sm={4}>
                                <Hidden xs sm>
                                    <Body color="white">{formatPrice(data?.suspenseAccount?.amount)} kr</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color="white">{formatPrice(data?.suspenseAccount?.amount)} kr</BodySmall>
                                </Visible>
                            </Col>
                            <Col lg={1} xs={2} sm={4}>
                                <Hidden xs sm>
                                    <Body color={theme.colors.textSecondary}>{data?.suspenseAccount?.amount < 0 ? 'Kredit' : 'Debit'}</Body>
                                </Hidden>
                                <Visible xs sm>
                                    <BodySmall color={theme.colors.textSecondary}>{data?.suspenseAccount?.amount < 0 ? 'Kredit' : 'Debit'}</BodySmall>
                                </Visible>
                            </Col>
                        </AccountRow>
                    )}
                </div>
            )}
        </div>
    );
};

export default SettlementDataAccounting;
