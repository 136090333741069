import * as React from 'react';
import { Container, Row } from 'react-grid-system';
import AutobookBanner from '../../molecules/AutobookBanner';
import { HorizontalSpacer, VerticalSpacer } from '../../atoms/Spacers';
import Title from '../../atoms/typography/Title';
import ActivateAutobook from '../../molecules/ActivateAutobook';
import SelectAccountsComponent from '../../molecules/SelectAccountsComponent';
import Subtitle from '../../atoms/typography/Subtitle';
import Body from '../../atoms/typography/Body';
import GradientButtonDark from '../../atoms/buttons/GradientButtonDark';
import FAQQuestion from '../../molecules/FAQQuestion';
import styled from 'styled-components';
import BodySmall from '../../atoms/typography/BodySmall';
import { PhoneIcon } from '../../atoms/icons/PhoneIcon';
import { MailIcon } from '../../atoms/icons/MailIcon';
import { PageWrapper } from '../../atoms/PageWrapper';

const ContactBox = styled.div`
    background: linear-gradient(181.89deg, rgba(255, 255, 255, 0.7) 27.48%, #fbfbfb 73.26%);
    box-shadow: 2px 4px 8px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    z-index: 10;
    padding: 1rem;
`;

const AutobookPage = () => {
    return (
        <PageWrapper>
            <Container>
                <AutobookBanner />
                <HorizontalSpacer spacing={2} />
                <Title color="white">Autobook</Title>
                <HorizontalSpacer spacing={2} />

                <ActivateAutobook />

                <HorizontalSpacer spacing={3} />

                <SelectAccountsComponent />

                <HorizontalSpacer spacing={3} />

                <div>
                    <Subtitle color="white">Avräkningar</Subtitle>
                    <HorizontalSpacer spacing={1} />
                    <Body color="white">
                        Du kan följa och se alla dina avräkningar genom att klicka på{' '}
                        <b>
                            <u>Avräkningar</u>
                        </b>{' '}
                        i menyn.
                    </Body>
                    <HorizontalSpacer spacing={1} />
                    <a href="/historik/avräkningar">
                        <GradientButtonDark title="Gå till avräkningar" />
                    </a>
                </div>
                <HorizontalSpacer spacing={3} />

                <div>
                    <Subtitle color="white">Integrationer</Subtitle>
                    <HorizontalSpacer spacing={1} />
                    <Body color="white">
                        Autobook kräver en aktiv integration med ditt bokföringssystem. Genom integrationen kan vi hämta fakturor direkt från ditt
                        bokföringssystem och automatiskt bokföra avräkningarna. Du väljer själv vilka funktioner du vill använda. Integrationer hanteras under
                        Inställningar i menyn.
                    </Body>
                    <HorizontalSpacer spacing={1} />
                    <a href="/inställningar/integrationer">
                        <GradientButtonDark title="Gå till integrationer" />
                    </a>
                </div>

                <HorizontalSpacer spacing={3} />
                <div>
                    <Subtitle color="white">Vanliga frågor</Subtitle>
                    <HorizontalSpacer spacing={1} />
                    <FAQQuestion
                        question="Hur ska jag bokföra fakturaköp?"
                        answer={
                            <>
                                När du säljer en eller flera fakturor till oss så skickar vi en ”avräkning” till dig. Avräkningen fungerar som ett
                                bokföringsunderlag för din försäljning och innehåller specifikationer såsom utbetalningsbelopp, avgifter och moms. Vilka konton
                                du använder kan variera beroende på hur många kostnadskonton du använder samt vilket konto du valt att få dina inbetalningar
                                till. I SIE-filen har vi lagt in de konton vi rekommenderar men det finns möjlighet att korrigera vissa av dessa om du önskar.
                            </>
                        }
                    />

                    <HorizontalSpacer spacing={1} />

                    <FAQQuestion question="Hur fungerar den automatiska bokföringen?" answer={<>Lorem ipsum text behövs här</>} />
                    <HorizontalSpacer spacing={1} />

                    <FAQQuestion
                        question="Hur vet jag att den automatiska bokföringen fungerar?"
                        answer={
                            <>
                                Du kan se här på mina sidor under avräkningar om den automatiska bokföringen gått igenom. Då har den en grön bock vid
                                avräkningen. Du kan även se bokföringen i ditt bokföringssystem
                            </>
                        }
                    />
                    <HorizontalSpacer spacing={1} />

                    <FAQQuestion question="Jag har fler frågor" answer={<>Kontakt oss!</>} />
                </div>

                <HorizontalSpacer spacing={3} />
                <div style={{ width: 300 }}>
                    <ContactBox>
                        <BodySmall fontWeight={700} color="black">
                            Behöver du support?
                        </BodySmall>
                        <HorizontalSpacer spacing={1} />
                        <a href="tel:0317882855">
                            <Row nogutter align="center">
                                <PhoneIcon />
                                <VerticalSpacer spacing={0.5} />
                                <Body>031 788 28 55</Body>
                            </Row>
                        </a>
                        <HorizontalSpacer spacing={1} />
                        <a href="mailto:onboarding@fg.se">
                            <Row nogutter align="center">
                                <MailIcon />
                                <VerticalSpacer spacing={0.5} />
                                <Body>onboarding@fg.se</Body>
                            </Row>
                        </a>
                    </ContactBox>
                </div>
            </Container>

            <HorizontalSpacer spacing={3} />
        </PageWrapper>
    );
};

export default AutobookPage;
